import React, { useEffect, useState } from "react";
import {
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBTextArea,
  MDBCardImage,
} from "mdb-react-ui-kit";
import DisplayChat from "./DisplayChat";
import Button from "react-bootstrap/Button";
import Microphone from "./Microphone";

export default function SzeptaczChat(props) {
  const jsonServerURL = "http://192.168.1.105:3000/conversations";

  const [updated, setUpdated] = useState(""); // after Enter pressed
  const [message, setMessage] = useState(""); // realtime input field

  const [conversation, setConversation] = useState({ items: [] }); // whole conversation stored in a list/array with objects{name,message} !!! ITEMS to have new JS object!
  let messageNb = 0;

  const fetchData = () => {
    // nieużywane pobieranie z jsona po odpaleniau useEffects []
    fetch(jsonServerURL)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data != null) messageNb = data.length;
        console.log(messageNb);
        setConversation(data);
      });
  };

  useEffect(() => {
    // fetchData();
  }, []);

  // useEffect(() => {
  //   if (conversations != null)
  //     console.log(conversations[2].conversation[2].name);
  // }, [conversations]);

  useEffect(() => {
    console.log(
      "useEffect SzeptaczChat - dodanie z kliknięteto guzika - " +
        props.clickedButton.topic +
        " index=" +
        props.clickedButton.index
    );
    if (props.clickedButton.index == 0) return;

    ///// tutaj lokalnie uzupelniam czat prośbą o szept
    // addConvMessage({
    //   name: "user",
    //   message: "Interesuje mnie temat <" + props.clickedButton.topic + ">",
    // });
  }, [props]);

  const addConvMessage = (convMessage) => {
    // adds one message to the list with name for user/bot
    var convMessages = conversation.items;
    convMessage.id = convMessages.length;
    console.log("addConvMessage convMessage.message = " + convMessage.message);

    ///wpisywanie do jsona

    // const requestOptions = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(convMessage),
    // };

    // fetch(jsonServerURL, requestOptions)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     /*tu był experyment na wpisanie pozyskanej wartości z serwera, ale zarzuciłem*/
    //   });

    convMessages.push(convMessage);
    setConversation({ items: convMessages });
  };

  const removeLastConvMessage = () => {
    // removes last message of given type: user /bot
    var convMessages = conversation.items;
    if (convMessages.length == 0) {
      return;
    }
    convMessages.pop();
    // do {
    // } while (condition);
    setConversation({ items: convMessages });
  };

  const updateLastConvMessage = (convMessage) => {
    // updates last message to given one
    console.log("updateLastConvMessage");
    var convMessages = conversation.items;
    convMessage.id = convMessages.length;
    if (convMessages.length > 0) {
      // now we'll check if the last message is the same type as new one
      // if yes, it will replace it
      // if not, the new one will be added
      // var lastId = convMessages.length - 1;
      var lastMsg = convMessages.at(-1);
      if (lastMsg.name == convMessage.name) {
        // the same type
        // convMessages.pop(); // remove the last message
        removeLastConvMessage();
      }
    }
    convMessages.push(convMessage); //add new one
    setConversation({ items: convMessages });
  };

  const setBotThinking = () => {
    // add message that illustrates "thinking" state of a bot
    console.log("setBotThinking");

    addConvMessage({
      name: "szeptaczBot",
      message: "...",
    });
  };

  const handleChange = (event) => {
    // inputfield change evnt handler and message updater
    setMessage(event.target.value);
  };

  const SendUserMessage = () => {
    // takes current message as confirmed by click or Enter and put it to update var and put it t the list
    console.log("SendUserMessage" + message);
    if (message != "" && message != "\n") {
      // to avoid empty messages and filled only with return sign for ENTER button \n

      // samo setUpdated spowoduje że komponent mikrophon wyśle to websocketem, a potem w odpowiedzi uzyska też to co go wzbudziło
      // a to jest publikowane na czacie...
      setUpdated(message);

      /// jaśli chcemy lokalnie uzupełniać czata, to poniższe okomentować
      // addConvMessage({
      //   name: "user",
      //   message: message,
      // });

      /// ponższe powoduje wpisywanie jakiejs dummy message od Szeptacza, do testów wyświetlania czata
      // SendBotMessage("podczas czekania na swoją kolej pogadaj ze mną...");
    }
    setMessage(""); // to empty the input field
  };

  const SendBotMessage = (text) => {
    // to create fake answer of the botSzeptacz
    addConvMessage({
      name: "szeptaczBot",
      message: text,
    });
  };

  const handleKeyDown = (event) => {
    //Enter key down event handler
    if (event.key === "Enter") {
      SendUserMessage();
    }
  };

  return (
    <MDBCard
      className="mb-1"
      id="chat1"
      style={{
        borderRadius: "3px",
        backgroundColor: "rgba(66, 66, 66,.8)",
      }}
    >
      <MDBCardBody className="col py-0 d-flex flex-column pb-0">
        <div className="row flex-fill">
          <DisplayChat conversationMessages={conversation.items} />
        </div>
        <div className="row px-3 py-0 align-items-center">
          <MDBCol className="col-10 ">
            <MDBTextArea
              className="form-outline"
              // label="Napisz do Szeptacza"
              rows={1}
              type="text"
              id="message"
              name="message"
              value={message}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              style={{
                // position: "fixed",
                // width: "100%",
                //left: "0",
                //right: "200px",
                backgroundColor: "black",
                color: "white",
              }}
            />
          </MDBCol>
          <MDBCol className="col-2 px-0 d-flex align-items-center justify-content-center">
            <Button variant="outline-secondary" onClick={SendUserMessage}>
              <MDBCardImage src="./images/send.png" fluid alt="..." />
            </Button>
          </MDBCol>
        </div>

        <div
          className="row py-0"
          style={{
            display: "grid",
            // height: "150px",
          }}
        >
          <Microphone
            getMessage={(message) => {
              // console.log("WRÓCIŁEM z " + message);
              // setMessage(message);
              //setUpdated(message.message);
              addConvMessage({
                name: message.name,
                message: message.message,
              });
            }}
            getClientNumber={(number) => {
              props.getClientNumber(number);
            }}
            postClientQuestion={(response) => {
              // this will post recognized client question
              var msgText = response.question;
              var msg = {
                name: "user",
                message: response.is_final ? msgText : msgText + " ...",
              };
              updateLastConvMessage(msg);
              if (response.is_final) {
                console.log("recognition finished");
                setBotThinking();
              }
            }}
            postBotResponseText={(responseText) => {
              // this will replace last message "thinking-style" to actual bot response
              // TODO: stop some animation..?
              var msg = {
                name: "szeptaczBot",
                message: responseText,
              };
              updateLastConvMessage(msg);
            }}
            clickedButton={props.clickedButton}
            client_chat={updated}
          ></Microphone>
        </div>
      </MDBCardBody>
    </MDBCard>
  );
}
