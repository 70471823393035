import React, { useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
} from "mdb-react-ui-kit";

export default function Numerek(props) {
  function getRandomInt(max) {
    return Math.floor(Math.random() * max) + 1;
  }
  let queueNum = getRandomInt(14);
  let seatNum = getRandomInt(7);

  return (
    <MDBCard
      background="black"
      className="mb-1"
      style={{
        borderRadius: "3px",
        color: "rgba(250, 250, 250,1)",
      }}
    >
      <MDBCardBody>
        <MDBRow>
          <MDBCol className="col-7 ">
            <MDBCardTitle>Twój numerek</MDBCardTitle>
            <MDBCardText>Stanowisko numer 00{seatNum}</MDBCardText>
          </MDBCol>
          <MDBCol>
            <MDBCardTitle
              style={{
                color: "#ff6600",
                fontSize: "44px",
              }}
            >
              {/* jakby się miało losować */}
              {/* {queueNum} */}
              {props.clientNumber}
            </MDBCardTitle>
          </MDBCol>
        </MDBRow>
        <MDBRow
          className="row align-items-center"
          style={{
            paddingTop: "0rem",
          }}
        >
          <MDBCol className="col">
            <MDBCardImage src="./images/clock.png" fluid alt="..." />
          </MDBCol>
          <MDBCol className="col-9 flex-fill">
            <MDBCardText>Czas oczekiwania: 7 minut</MDBCardText>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  );
}
