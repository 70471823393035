import React, { useState } from "react";

function BotText(props) {
  const textValue = props.text;

  return (
    <div className="d-flex flex-row justify-content-start mb-4">
      <img
        className={props.text === "..." ? "gelatine" : ""}
        // className={"avatarImg" + (props.text === "..." ? "Rotation" : "")}
        // src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
        src="images\szeptaczAvatar.png"
        alt="avatar 1"
        style={{ width: "45px", height: "100%" }}
      />
      <div className={props.text === "..." ? "gelatine" : ""}>
        <div
          className="p-3 ms-3"
          style={{
            borderRadius: "30px",
            backgroundColor: "rgba(255,120,0,1)",
          }}
        >
          <p className="small mb-0">{textValue}</p>
        </div>
      </div>
    </div>
  );
}
export default BotText;
