import { useEffect, useState } from "react";
import styled from "styled-components";
import SzeptaczChat from "./SzeptaczChat";
import Numerek from "./Numerek";
import Nowosc from "./Nowosc";
import InfoUruchom from "./InfoUruchom";
import Karuzela from "./Karuzela";
import ButtonsGallery from "./ButtonsGallery";

import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import "./App.css";

function App() {
  const [clickedButton, setClickedButton] = useState({
    index: 0,
    topic: "notSetYet",
  });

  const [clientNumber, setClientNumber] = useState(0);

  return (
    <div className="container-fluid justify-content-center bg-dark w-100 h-100 p-0">
      <div className="col vh-100 p-0 d-flex flex-column bg-dark">
        <header className="header justify-content-center">
          <section>
            <Numerek clientNumber={clientNumber}></Numerek>
          </section>
          {/* <section>
            <InfoUruchom></InfoUruchom>
          </section>
          <section>
            <Nowosc> </Nowosc>
          </section> */}
        </header>

        <div>
          {/* <section>
            
          </section> */}

          {/* <section>
            <Karuzela></Karuzela>
          </section> */}
        </div>
        <footer className="footer fixed-bottom">
          <section className="row justify-content-center">
            <SzeptaczChat
              clickedButton={clickedButton}
              getClientNumber={(clientNumber_) => {
                setClientNumber(clientNumber_);
              }}
            ></SzeptaczChat>
          </section>

          <div className="row justify-content-center">
            <ButtonsGallery
              getClicked={(clickedButton_) => {
                // console.log("Wrocił z 3buttonów " + clickedButton2.topic);
                setClickedButton(clickedButton_);
              }}
            ></ButtonsGallery>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
