import { io } from "https://cdn.socket.io/4.7.5/socket.io.esm.min.js";

const URL =
  process.env.NODE_ENV === "https://szeptacz.ott.rd.tp.pl"
    ? undefined
    : "https://szeptacz.ott.rd.tp.pl";
//   : "http://localhost:4000";

//export const socket = io(URL);

export const socket = io(URL, {
  path: "/ws/socket.io",
  transports: ["websocket"],
  autoConnect: true,
});
