import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

import Button from "react-bootstrap/Button";

export default function ButtonsGallery(props) {
  const firtsButtonClicked = () => {
    props.getClicked({
      index: 1,
      topic: "mój orange",
    });
  };
  const secondButtonClicked = () => {
    props.getClicked({
      index: 2,
      topic: "program re",
    });
  };
  const thirdButtonClicked = () => {
    props.getClicked({
      index: 3,
      topic: "światłowód",
    });
  };

  return (
    <MDBCard
      // className=""
      background="black"
      style={{
        borderRadius: "3px",
        color: "rgba(250, 250, 250,1)",
        //   bottom: "0",
        //   position: "fixed",
        //   left: "0",
        //   right: "0",
      }}
    >
      <MDBCardBody>
        <MDBCardTitle>Posłuchaj Szeptów</MDBCardTitle>
        <MDBRow className="">
          <MDBCol className="p-0 d-flex justify-content-center">
            <Button className="buttonSzeptow" onClick={firtsButtonClicked}>
              <div
                className="row py-0"
                style={{
                  display: "grid",
                  alignItems: "end",
                }}
              >
                <img
                  className="imgSzeptow"
                  src="./images/guzik_mojorange.png"
                  alt="Your Alt Text"
                ></img>

                <p className="textOnButton">MÓJ ORANGE</p>
              </div>
            </Button>
          </MDBCol>
          <MDBCol className="p-0 p-0 d-flex justify-content-center">
            <Button className="buttonSzeptow" onClick={secondButtonClicked}>
              <div
                className="row py-0"
                style={{
                  display: "grid",
                  alignItems: "end",
                }}
              >
                <img
                  className="imgSzeptow"
                  src="./images/guzik_programre.png"
                  alt="Your Alt Text"
                ></img>
                <p className="textOnButton">PROGRAM RE</p>
              </div>
            </Button>
          </MDBCol>
          <MDBCol className="p-0 p-0 d-flex justify-content-center">
            <Button className="buttonSzeptow" onClick={thirdButtonClicked}>
              <div
                className="row py-0"
                style={{
                  display: "grid",
                  alignItems: "end",
                }}
              >
                <img
                  className="imgSzeptow"
                  src="./images/guzik_swiatlowod.png"
                  alt="Your Alt Text"
                ></img>
                <p className="textOnButton">ŚWIATŁOWÓD</p>
              </div>
            </Button>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  );
}
